/*!
 * Start Bootstrap - Landing Page v5.0.8 (https://startbootstrap.com/themes/landing-page)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-landing-page/blob/master/LICENSE)
 */

 body {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
  }
  
  .features-icons {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  
  .features-icons .features-icons-item {
    max-width: 20rem;
  }
  
  .features-icons .features-icons-item .features-icons-icon {
    height: 7rem;
  }
  
  .features-icons .features-icons-item .features-icons-icon i {
    font-size: 4.5rem;
  }
  
  .features-icons .features-icons-item:hover .features-icons-icon i {
    font-size: 5rem;
  }
  
  footer.footer {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  
  .selectsettings {
    margin-right: 10px;
  }